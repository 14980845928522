export const seoData = {
  home: {
    title: "Find and Fight Deepfakes Affecting Students",
    description: "Protect your identity today. Start removing harmful deepfake content with experts and free guides!",
    image: "",
    keywords: "deepfake help, student protection, deepfake removal, school safety",
    canonicalUrl: "/",
    structuredData: {
      "@context": "https://schema.org",
      "@type": "WebSite",
      "name": "Deepfake Help",
      "url": "https://deepfakehelp.com",
      "description": "Resource center for students affected by deepfakes"
    }
  },
  reportOverview: {
    title: "Report & Remove Deepfake Content",
    description: "Learn how to report and remove harmful deepfake content. Step-by-step guides for various platforms.",
    image: "/default-og-image.png",
    keywords: "report deepfake, remove deepfake, content removal, platform reporting",
    canonicalUrl: "/report-deepfake-content",
    structuredData: {
      "@context": "https://schema.org",
      "@type": "HowTo",
      "name": "How to Report Deepfake Content",
      "description": "Step-by-step guide for reporting and removing deepfake content"
    }
  },
  contact: {
    title: "Contact Us - Get Help with Deepfake Issues",
    description: "Reach out for support with deepfake-related issues. We're here to help students and educational institutions.",
    image: "",
    keywords: "contact, support, deepfake help, student assistance",
    canonicalUrl: "/contact",
    structuredData: {
      "@context": "https://schema.org",
      "@type": "ContactPage",
      "name": "Contact Deepfake Help",
      "description": "Contact page for deepfake support"
    }
  },
  gettingStarted: {
    title: "Getting Started - Understanding Deepfakes & Protection",
    description: "Learn the basics of deepfakes and how to protect yourself. Essential information for students and educators.",
    image: "",
    keywords: "deepfake basics, protection guide, student safety, deepfake education",
    canonicalUrl: "/getting-started",
    structuredData: {
      "@context": "https://schema.org",
      "@type": "Article",
      "name": "Getting Started with Deepfake Protection",
      "description": "Comprehensive guide to understanding and protecting against deepfakes"
    }
  },
  reportDeepfake: {
    title: "Report Deepfake Content - Step by Step Guide",
    description: "Comprehensive guide on reporting and removing harmful deepfake content across different platforms.",
    image: "",
    keywords: "report deepfake, content removal, platform guidelines, takedown process",
    canonicalUrl: "/report-deepfake-content",
    structuredData: {
      "@context": "https://schema.org",
      "@type": "HowTo",
      "name": "Report and Remove Deepfake Content",
      "description": "Step-by-step instructions for reporting and removing deepfake content"
    }
  },
  platforms: {
    title: "Platform-Specific Deepfake Reporting Guidelines",
    description: "Learn how to report deepfakes on different social media and content platforms. Platform-specific instructions and tips.",
    image: "",
    keywords: "social media reporting, platform guidelines, content removal, reporting process",
    canonicalUrl: "/platforms",
    structuredData: {
      "@context": "https://schema.org",
      "@type": "Guide",
      "name": "Platform Reporting Guidelines",
      "description": "Platform-specific guidelines for reporting deepfake content"
    }
  },
  organizations: {
    title: "Organizations Fighting Deepfakes - Support Network",
    description: "Connect with organizations dedicated to combating deepfakes and supporting affected individuals.",
    image: "",
    keywords: "support organizations, deepfake help, advocacy groups, support network",
    canonicalUrl: "/organizations",
    structuredData: {
      "@context": "https://schema.org",
      "@type": "Organization",
      "name": "Deepfake Support Organizations",
      "description": "Directory of organizations helping with deepfake issues"
    }
  },
  lawyers: {
    title: "Find Legal Help for Deepfake Cases",
    description: "Connect with lawyers specializing in deepfake cases and digital privacy rights. Expert legal support for students.",
    image: "",
    keywords: "deepfake lawyers, legal help, digital rights, privacy law",
    canonicalUrl: "/deepfake-lawyers",
    structuredData: {
      "@context": "https://schema.org",
      "@type": "Service",
      "name": "Deepfake Legal Support",
      "description": "Legal assistance for deepfake-related cases"
    }
  },
  about: {
    title: "About Us - Our Mission Against Deepfakes",
    description: "Learn about our mission to protect students from deepfakes and provide essential support resources.",
    image: "",
    keywords: "about us, mission statement, deepfake protection, student support",
    canonicalUrl: "/about-us",
    structuredData: {
      "@context": "https://schema.org",
      "@type": "AboutPage",
      "name": "About Deepfake Help",
      "description": "Our mission and commitment to fighting deepfakes"
    }
  },
  // Add more pages as needed
};

function logSeoData() {
  console.log(seoData); // This will log the entire seoData object to the console
}

logSeoData(); // Call the function to log the data
import React, { useState } from 'react';
import { Search, ChevronUp } from 'lucide-react'; 
import Footer from '../Footer'; // Import the Footer component
import SEO from '../SEO';
import { seoData } from '../seo.config';
import { Helmet } from 'react-helmet';


// Ensure 'guides' is defined or imported
// import guides from 'path/to/guides'; // Uncomment and adjust the path if guides is in another file

const platforms = {
  'Discord': {
    'app': [
      {
        'title': 'To make a complaint about an image on Discord:',
        'intro': 'Follow these steps to report inappropriate content on Discord:',
        'steps': [
          'Log into Discord',
          'Find the image or video you want to report',
          'Press and hold on the message where the image was sent',
          'Tap “Report”',
          'Select “Abuse or harassment”',
          'Select "Content targeting or involving a minor"',
          'Select the appropriate option (i.e., "A minor is posting or sending sexual messages")',
          'Tap "Submit Report"'
        ]
      }
    ],
    'desktop': [
      {
        'title': 'To make a complaint about an image on Discord:',
        'intro': 'Use the following steps to report inappropriate content on Discord:',
        'steps': [
          'Log into Discord',
          'Find the image or video you want to report',
          'Click on the three dots and select “Report Message”',
          'Select "Abuse or Harassment"',
          'Select "Content targeting or involving a minor"',
          'Select the appropriate option (i.e., "A minor is posting or sending sexual messages")',
          'Tap "Submit Report"'
        ]
      }
    ]
  },
  'Facebook': {
    'app': [
      {
        'title': 'To report a nude or sexually explicit image or video posted on Facebook:',
        'intro': 'Follow these steps to report inappropriate content on Facebook:',
        'steps': [
          'Log into Facebook',
          'Find the image or video you want to report in the app',
          'Tap on the three dots “•••” in the top right corner of the image',
          'Tap “Report Photo"',
          'Tap "Nudity"',
          'Tap the most appropriate response (i.e., "Child Nudity" or "Sharing private images")',
          'Tap "Submit"'
        ]
      },
      {
        'title': 'To block a user on Facebook:',
        'intro': 'If you want to block someone on Facebook, follow these steps:',
        'steps': [
          'Log into Facebook',
          'Find the user you want to block',
          'Tap on their profile',
          'Tap on the three dots “•••” at the top of their profile underneath their profile image',
          'Select "Block"'
        ]
      }
    ],
    'desktop': [
      {
        'title': 'To make a complaint about an image or video on Facebook:',
        'intro': 'Use the following steps to report an image or video:',
        'steps': [
          'Log into Facebook',
          'Click on the image/video you would like to report.',
          'Tap on the three dots “•••” in the top right corner of the page',
          'Select “Find Support or report Photo”',
          'Select “Something Else”',
          'Select "Non-Consensual Intimate Images"',
          'Click "Submit"'
        ]
      },
      {
        'title': 'To report someone threatening to post exploitative images or videos:',
        'intro': 'If someone is threatening to share inappropriate content, follow these steps:',
        'steps': [
          'Open the conversation that you want to report',
          'Click on the user’s name in the top left corner of the conversation',
          'Click “Report”',
          'Select "Sharing Inappropriate Things"',
          'Select the most appropriate option (i.e., "Sharing Private Images")',
          'The report will be automatically sent after selecting the option.'
        ]
      },
      {
        'title': 'For complaints about images/videos posted on an individual’s Facebook profile:',
        'intro': 'To report a post on someone’s profile, follow these steps:',
        'steps': [
          'Log into Facebook',
          'Go to the post you want to report',
          'Click on the three dots “•••” in the top right corner of the page',
          'Select “Report Post”',
          'Select the most appropriate response (i.e., “Nudity or Sexual Content”)',
          'Select the most appropriate response (i.e., “Involves a Child”)',
          'Submit'
        ]
      },
      {
        'title': 'Facebook Copyright Infringement (DMCA):',
        'intro': 'If you believe your copyright has been infringed, follow these steps:',
        'steps': [
          <span className="break-words" >'Access https://www.facebook.com/help/contact/1409697672616547'</span>,
          'Select the most appropriate response',
          'Read the information provided by Facebook and select the most appropriate response',
          'If you wish to move forward, fill out the information requested of you by Facebook'
        ]
      }
    ]
  },
  'Google': {
    'app': [
      {
        'title': 'To make a complaint about images or videos from Google Image Results:',
        'intro': 'Images that appear on Google image results are actually hosted by a different company. In order to remove your intimate image or video that has been shared without your consent and is appearing in Google search results, follow these steps:',
        'steps': [
          ' Determine where the image is displayed:',
          '   - Find the image on Google Image Results.',
          '   - The hosting website will be listed under the image in small text.',
          '   - Please see “Other Sites” for information on how to contact the provider to request the removal of your image/video.',
          ' Submit a request to Google to remove the content:',
          <span className="break-words" >   - Access https://support.google.com/websearch/troubleshooter/9685456#ts=2889054%2C2889099%2C9166584</span>,
          '   - Select the appropriate response (i.e., “Remove information you see in a Google Search”).',
          '   - Select the appropriate response (i.e., “In Google\'s search results and on a website”).',
          '   - Select the appropriate response (i.e., “No, I prefer not to.”)',
          '   - Select the appropriate response (i.e., “Nude or sexually explicit items”).',
          '   - Select the appropriate response (i.e., “A nude, sexual or intimate picture or video”).',
          '   - Click “Yes or No”.',
          '   - Click “Yes or No”.',
          '   - Add personal information.',
          '   - Add URLs.',
          '   - Click Submit.'
        ]
      },
      {
        'title': 'To make a complaint about cached content on Google:',
        'intro': 'Once an image/video has been removed from a website, the content may continue to appear in Google search results. This happens because Google takes a snapshot of each page and caches (stores) that version. It may take some time for Google to revisit the webpage to update its cache, which is why your image/video may continue to show up in Google results even though it’s been removed from the website.',
        'steps': [
          'Submit a request to Google to have a cached page removed:',
          '   - Log into your Google account.',
          '   - Access https://search.google.com/search-console/remove-outdated-content?utm_source=wmx&utm_medium=report-banner&utm_content=removals',
          '   - Select new request.',
          '   - Provide the URL and click “Request Removal”.'
        ]
      },
      {
        'title': 'To make a complaint about images or videos on other Google products (Google+, YouTube, etc.):',
        'intro': 'Follow the instructions presented regarding removing the specific Google product:',
        'steps': [
          '   - Access https://support.google.com/legal/troubleshooter/1114905?hl=en (i.e., Google+, YouTube, etc.).',
          '   - Follow the instructions presented regarding removing the specific Google product.'
        ]
      }
    ],
    'desktop': [] // If you have desktop instructions, add them here
  },
  'Instagram': {
    'app': [
      {
        'title': 'To make a complaint about an image or video on Instagram:',
        'intro': 'Follow these steps to report inappropriate content on Instagram:',
        'steps': [
          'Log into Instagram',
          'Find the image or video you want to report in the app',
          'Tap on the three dots “•••” in the top right corner of the image',
          'Tap “Report” then tap “Nudity or Sexual Activity”',
          'Tap the most appropriate response (i.e., “Involves a Child”)',
          'Tap “Submit Report”'
        ]
      },
      {
        'title': 'To make a complaint regarding a user threatening to post sexual images on Instagram:',
        'intro': 'If someone is threatening to share inappropriate content, follow these steps:',
        'steps': [
          'Log into Instagram',
          'Find the user you want to report in the app',
          'Tap on the three dots “••• in the top right corner of the image',
          'Tap "Report"',
          'Tap “Something about this account”',
          'Tap the most appropriate response (i.e., “Something else”)',
          'Tap the most appropriate response (i.e., “Bullying or harassment”)',
          'Tap the most appropriate response (i.e., “Me”)',
          'OR',
          'Access https://help.instagram.com/contact/584460464982589',
          'Complete the form to report images, videos, comments, or profiles on Instagram that are bullying or harassing others by selecting the most appropriate options',
          'Click “Send”'
        ]
      },
      {
        'title': 'To block a user on Instagram:',
        'intro': 'Follow these steps to block a user on Instagram:',
        'steps': [
          'Log into Instagram',
          'Find the user you want to report in the app',
          'Click on their profile image to view their full page',
          'Tap the three dots “•••” in the upper right-hand corner',
          'Choose "Block" or "Block and Report"'
        ]
      },
      {
        'title': 'Instagram Copyright Infringement (DMCA):',
        'intro': 'If you believe your copyright has been infringed, follow these steps:',
        'steps': [
          'Access https://help.instagram.com/contact/552695131608132 for more information.'
        ]
      }
    ],
    'desktop': [
      {
        'title': 'To make a complaint about an image on Instagram:',
        'intro': 'Follow these steps to report inappropriate content on Instagram:',
        'steps': [
          'Log into Instagram',
          'Access https://help.instagram.com/contact/504521742987441',
          'Complete the form to report photos or videos on Instagram that violate your privacy',
          'Click “Send”'
        ]
      },
      {
        'title': 'To make a complaint regarding a user threatening to post sexual images on Instagram:',
        'intro': 'If someone is threatening to share inappropriate content, follow these steps:',
        'steps': [
          'Access https://help.instagram.com/contact/584460464982589',
          'Complete the form to report images, videos, comments, or profiles on Instagram that are bullying or harassing others by selecting the most appropriate options',
          'Click “Send”'
        ]
      },
      {
        'title': 'Instagram Copyright Infringement (DMCA):',
        'intro': 'If you believe your copyright has been infringed, follow these steps:',
        'steps': [
          'Access https://help.instagram.com/contact/552695131608132',
          'Select “I am the rights owner”',
          'Complete the form to report images, videos, ads, or other content that may violate copyright',
          'Provide electronic signature and click "Send"',
          'If you wish to move forward, fill out the information requested of you by Instagram.'
        ]
      }
    ]
  },
  'Imgur': {
    app: [
      {
        title: 'To make a complaint about an image on Imgur:',
        intro: 'Follow these steps to report inappropriate content on Imgur:',
        steps: [
          'Click on Post',
          'Tap the “•••” on the top right corner of the post',
          'Tap “Report”',
          'Tap “Report” again'
        ]
      },
      {
        title: 'To make a complaint on your intimate images on Imgur:',
        intro: 'If you need to report intimate images, follow these steps:',
        steps: [
          'Go to your profile',
          'Tap the gear icon in the top right corner',
          'Tap “Help and Support”',
          'Tap “Removal Request”',
          'Select "Get Help"',
          'Tap the three lines on the top right, and select "Contact Support"',
          'Select the most appropriate request (i.e., "I need an image or post removed")',
          'Provide the Full Image or Album URL and a detailed reason why the images should be removed (i.e., nude or sexually explicit photo or video of you as a child that has been shared without your consent)',
          'Tap “Submit”'
        ]
      }
    ],
    desktop: [
      {
        title: 'To make a complaint about an image on Imgur:',
        intro: 'Follow these steps to report inappropriate content on Imgur:',
        steps: [
          'Access http://imgur.com/removalrequest',
          'Provide the Full Image or Album URL and a detailed reason why the images should be removed (i.e., nude or sexually explicit photo or video of you as a child that has been shared without your consent)',
          'Click “Submit”',
          'You can also report a post by clicking the “•••” on the bottom right corner of the post and following the report options listed above.'
        ]
      },
      {
        title: 'Imgur Copyright Infringement (DMCA):',
        intro: 'If you believe your copyright has been infringed, follow these steps:',
        steps: [
          'Access http://imgur.com/tos#dmca for additional information.'
        ]
      }
    ]
  },
  'Kik': {
    app: [
      {
        title: 'To report a user on Kik (if you have received messages from them):',
        intro: 'Follow these steps to report a user on Kik:',
        steps: [
          'In the chat, tap their profile picture or press and hold the chat bubble',
          'Tap “Report User”',
          'Tap “This person is being abusive”',
          'You can check off/on the option to include the chat history in your report',
          'Tap “Report and Block”'
        ]
      },
      {
        title: 'To report a user on Kik (if you don’t have messages from them):',
        intro: 'Follow these steps to report a user on Kik without prior messages:',
        steps: [
          'At the top of the chat, tap the “Display Name”',
          'Tap the vertical dots icon in the top right corner',
          'Tap “Report User”',
          'Tap “This person is being abusive”',
          'You can check off/on the option to include the chat history in your report',
          'Tap “Report and Block”'
        ]
      },
      {
        title: 'Kik Copyright Infringement (DMCA):',
        intro: 'If you believe your copyright has been infringed, follow these steps:',
        steps: [
          'Access https://www.kik.com/terms-of-service/ and click on “6. COPYRIGHT, TRADEMARK AND LIMITED LICENSES” for more information.'
        ]
      }
    ],
  
  'desktop': [] // If you have desktop instructions, add them here
  },
  'Microsoft Products': {
    app: [
      {
        title: 'To make a complaint about images or videos on Bing Image Results:',
        intro: 'Images that appear on Bing image results are actually hosted by a different company. You can flag an image on Bing as inappropriate by following these steps:',
        steps: [
          'Click the “••• More” then “Flag as inappropriate.”',
          'Select “Child Sexual Abuse then click Submit.',
          'In order to remove images that appear in Bing image results, determine where the image is displayed:',
          '   - Click the image in the Bing search results to view the full image.',
          '   - On the bottom right, click on the three dots.',
          '   - Select "Flag as Inappropriate".',
          '   - Select "Child Sexual Abuse".'
        ]
      },
      {
        title: 'To make a complaint about cached content on Bing:',
        intro: 'If the image/video has already been removed by the host but is still displaying on the Bing Image Results, you may submit a Page Removal Request.',
        steps: [
          'Access: https://www.bing.com/webmasters/tools/contentremoval and sign in to your Microsoft account.',
          'In the Content URL input box, enter the exact URL you found in the Bing web results.',
          'In the Removal Type drop-down menu select Page Removal.',
          'Click Submit.',
          'When you click submit, Microsoft will run a check whether the page is actually no longer available on the web. If that is the case, Microsoft will submit the request and add it to the Submission History table. However, if Microsoft detects that the page is still live on the web they will prompt that you can only submit an outdated cache removal.'
        ]
      },
      {
        title: 'To make a complaint about images or videos on other Microsoft products (OneDrive, Xbox Live):',
        intro: 'Follow these steps to report inappropriate content on other Microsoft products:',
        steps: [
          <span className="break-words">Access https://support.microsoft.com/en-us/getsupport?oaspworkflow=start_1.0.0.0&wfname=capsub&productkey=RevengePorn&wa=wsignin1.0&ccsid=636119840033448632</span>,
          'Complete the form, including providing your name, a link to the content, and your email address.',
          'Select the most appropriate response to listed questions (i.e., “have you ever agreed to the distribution of the photo or video?”).'
        ]
      },
      {
        title: 'Microsoft Copyright Infringement (DMCA):',
        intro: 'If you believe your copyright has been infringed, follow these steps:',
        steps: [
          'Access https://www.microsoft.com/en-us/concern/dmca and provide the requested information.'
        ]
      }
   ],
    'desktop': [] // If you have desktop instructions, add them here
  },
  'Reddit': {
    app: [
      {
        title: 'To report a nude or sexually explicit image or video posted on Reddit:',
        intro: 'Follow these steps to report inappropriate content on Reddit:',
        steps: [
          'Log into Reddit',
          'Find the image or video you want to report in the app',
          'Tap on the three dots “•••” in the top right corner of the image',
          'Tap “Report"',
          'Select the most appropriate reason (i.e., "Non-consensual intimate media")',
          'Select who the non-consensual media is of (yourself or someone else)',
          'Hit “Submit”'
        ]
      },
      {
        title: 'Reddit Copyright Infringement (DMCA):',
        intro: 'If you believe your copyright has been infringed, follow these steps:',
        steps: [
          'Log into Reddit',
          'Find the image or video you want to report in the app',
          'Tap on the three dots “•••” in the top right corner of the image',
          'Tap “Report"',
          'Select “Copyright Violation”',
          'Tap "Next"',
          'Select the most appropriate response (i.e., "Yours or an individual or entity you represent")',
          'Click "File a Complaint"',
          'Fill out all requested information on the form',
          'Hit "Submit"'
        ]
      }
    ],
    desktop: [
      {
        title: 'To report an explicit image or video on Reddit:',
        intro: 'Follow these steps to report explicit content on Reddit:',
        steps: [
          'Log into Reddit',
          'Tap on the three dots “•••” in the bottom right corner of the image',
          'Tap “Report”',
          'Select “Sexualization of Minors”',
          'Click “Next”',
          'Select who the image or video is about',
          'Hit “Submit”'
        ]
      },
      {
        title: 'Reddit Copyright Infringement (DMCA):',
        intro: 'If you believe your copyright has been infringed, follow these steps:',
        steps: [
          'Log into Reddit',
          'Find the image or video you want to report in the app',
          'Tap on the three dots “•••” in the top right corner of the image',
          'Tap “Report"',
          'Select “Copyright violation”',
          'Click “Next”',
          'Select who’s copyright it is',
          'Hit “Submit”',
          'OR',
          'Access reddithelp.com/hc/en-us/requests/new',
          'Select "Report Form"',
          'Select "I want to report other issues"',
          'Select "It infringes my copyright"',
          'Fill out the form with all available information',
          'Hit “Submit”'
        ]
      }
    ]
  },
  'Snapchat': {
    app: [
      {
        title: 'To make a complaint on a Story or Snap:',
        intro: 'Follow these steps to report inappropriate content on Snapchat:',
        steps: [
          'Press and hold on the Snap until a list of options appear',
          'Tap on “Report”',
          'Select the appropriate reporting option (i.e., "Nudity or sexual content")',
          'Select the most appropriate reason (i.e., "it is an inappropriate snap of me")',
          'Submit your report',
          'Once submitted, you will have the option to block the user'
        ]
      },
      {
        title: 'To Report a Snap User:',
        intro: 'If you want to report a user on Snapchat, follow these steps:',
        steps: [
          'Click and hold on the user\'s name',
          'Select Manage Friendship',
          'You can choose to Report and Block User',
          'Select the appropriate reporting option (i.e., they\'re posting inappropriate content, they\'re a spammer)',
          'Select the most appropriate reason (i.e., nudity and sexual content; they leaked/are threatening to leak my nudes; threats, violence, and dangerous behavior)',
          'Write more about what\'s happening (required)',
          'Submit your report'
        ]
      },
      {
        title: 'To Report a Snap or Snap Story:',
        intro: 'Follow these steps to report a Snap or Snap Story:',
        steps: [
          'Click on Story',
          'Tap on the three vertical dots on the top right corner',
          'Select Report Snap',
          'Select the most appropriate reason for reporting',
          'Write more about what\'s happening (required)',
          'Tap Submit'
        ]
      },
      {
        title: 'If you don\'t have a Snapchat account or are unable to report a safety concern in-app:',
        intro: 'You can report any issue on the Snapchat Support site:',
        steps: [
          'Access https://support.snapchat.com/en-US/i-need-help?start=5153567363039232'
        ]
      }
    ],
    desktop: [] // If there are desktop instructions, add them here
  },
  'TikTok': {
    app: [
      {
        title: 'To make a complaint about an account on TikTok:',
        intro: 'Follow these steps to report an account on TikTok:',
        steps: [
          'Go to the profile of the account you want to report',
          'Tap the arrow at the top right corner',
          'Tap “Report”',
          'Tap "Report Account"',
          'Select the most appropriate response (i.e., Posting Inappropriate Content)',
          'Select the most appropriate response to what was posted (i.e., Nudity and Sexual Content)',
          'Select the most appropriate response (i.e., "Youth Sexual Activity, Solicitation, and Exploitation")',
          'Press "Submit"'
        ]
      },
      {
        title: 'To make a complaint about a video on TikTok:',
        intro: 'Follow these steps to report a video on TikTok:',
        steps: [
          'Open the video and tap the Arrow icon in the bottom right OR Tap and hold on the video',
          'Tap “Report”',
          'Select the most appropriate response to what was posted (i.e., "Nudity and Sexual Content")',
          'Select the most appropriate response to what was posted (i.e., "Sexually suggestive behavior by youth")',
          'Press "Submit"'
        ]
      },
      {
        title: 'To make a complaint about a comment on TikTok:',
        intro: 'Follow these steps to report a comment on TikTok:',
        steps: [
          'Tap and hold the comment you’d like to report',
          'Tap “Report”',
          'Select the most appropriate response to what was posted',
          'Press "Submit"'
        ]
      },
      {
        title: 'To make a complaint about a message on TikTok:',
        intro: 'Follow these steps to report a message on TikTok:',
        steps: [
          'Open the conversation and tap and hold the message you would like to report',
          'Tap “Report”',
          'Select all messages that apply for being reported',
          'Select the most appropriate response to what was sent',
          'Press "Confirm"',
          'Press "Submit"'
        ]
      },
      {
        title: 'TikTok Copyright Infringement (DMCA):',
        intro: 'If you believe your copyright has been infringed, follow these steps:',
        steps: [
          'Access https://www.tiktok.com/legal/copyright-policy for additional information.'
        ]
      }
    ],
    desktop: [] // If there are desktop instructions, add them here
  },
  'Tumblr': {
    app: [
      {
        title: 'To make a complaint about images or videos posted on Tumblr:',
        intro: 'Follow these steps to report inappropriate content on Tumblr:',
        steps: [
          'Tap the “•••” in the top right corner of the post',
          'Tap "Report"',
          'Tap “Report adult content”'
        ]
      },
      {
        title: 'To make a complaint on your intimate images on Tumblr:',
        intro: 'If you need to report intimate images, follow these steps:',
        steps: [
          'Tap the “•••” in the top right corner of the post',
          'Tap "Report"',
          'Tap “Report Something Else”',
          'Tap “Harm to Minors”',
          'Tap “Is the content you are reporting suspected child sexual abuse material?”',
          'Provide a detailed reason why the images should be removed (i.e., nude or sexually explicit photo or video of you as a child that has been shared without your consent)',
          'Tap “Submit”'
        ]
      },
      {
        title: 'To make a complaint regarding a user threatening to post sexual images on Tumblr:',
        intro: 'Follow these steps to report harassment on Tumblr:',
        steps: [
          'Tap the person icon on the bottom right',
          'Tap the gear icon in the top right corner',
          'Tap “General settings”',
          'Tap “Report Abuse”',
          'Tap “I’m being harassed”',
          'Select yes if you are the one being harassed',
          'Tap “My privacy is being violated (i.e., explicit private photos or personal contact information).”',
          'Tap “My explicit private images”',
          'Provide the Full Image or Album URL and a detailed reason why the images should be removed (i.e., nude or sexually explicit photo or video of you as a child that has been shared without your consent)',
          'Tap “Submit”'
        ]
      }
    ],
    desktop: [
      {
        title: 'To make a complaint about images or videos posted on Tumblr:',
        intro: 'Follow these steps to report inappropriate content on Tumblr:',
        steps: [
          'Access https://www.tumblr.com/abuse/privacy',
          'Select who\'s privacy is being violated',
          'Select the most appropriate response (i.e., “my explicit private images”)',
          'Provide the URL of the post containing the image/video, or private information, your name, and email address',
          'Click “Submit”',
          'You can also access https://www.tumblr.com/abuse/minors and click “Harm to Minors” then “Is the content you are reporting suspected child sexual abuse material?” On the last page, provide the URL of the post containing the image/video, or private information, your name, and email address'
        ]
      },
      {
        title: 'Tumblr Copyright Infringement (DMCA):',
        intro: 'If you believe your copyright has been infringed, follow these steps:',
        steps: [
          'Access https://www.tumblr.com/dmca and provide the requested information.'
        ]
      }
    ]
  },
  'X': {
    app: [
      {
        title: 'To report a tweet on X:',
        intro: 'Follow these steps to report inappropriate content on X:',
        steps: [
          'Log into X',
          'On the tweet you want to report, tap on the three dots “•••” in the top right corner of the post',
          'Tap “Report Post”',
          'Tap “Child Safety”',
          'Tap the most appropriate response (i.e., "Selling or distributing sexual explicit content involving a minor")',
          'Tap “Submit”'
        ]
      },
      {
        title: 'To report an entire profile on X:',
        intro: 'If you want to report a user’s profile, follow these steps:',
        steps: [
          'You can also report the entire profile by tapping the “•••” on the profile page and clicking "Report [Account Name]"',
          'Select the most appropriate response (i.e., "Child Safety")',
          'Select the most appropriate response (i.e., "Selling or distributing sexually explicit content involving a minor")',
          'Provide any additional information if possible',
          'Press "Submit"'
        ]
      }
    ],
    desktop: [
      {
        title: 'To make a complaint about images or videos posted on X:',
        intro: 'You can report using the same steps taken on the app or follow these steps:',
        steps: [
          'You can access https://help.twitter.com/forms/private_information',
          'Select the most appropriate option regarding the issue you are having (i.e., "I\'d like to report a child sexual exploitation issue")',
          'Select where you are seeing the incident ("X")',
          'Complete the form by including your email address in the appropriate field',
          'In the field marked "Please provide more details about what\'s happening," provide the information outlined below:',
          '   - Additional account details, including the account username and a link to the content or account',
          '   - Your age at the time the image/video was taken as well as your current age',
          '   - That you did not post it, did not agree to it being posted, and want it removed.',
          'Tap "Submit”'
        ]
      },
      {
        title: 'To report multiple tweets on X:',
        intro: 'If you need to report multiple tweets, follow these steps:',
        steps: [
          'Use https://help.twitter.com/forms/moment_reporting'
        ]
      },
      {
        title: 'X Copyright Infringement (DMCA):',
        intro: 'If you believe your copyright has been infringed, follow these steps:',
        steps: [
          'Access https://help.twitter.com/forms/dmca and provide the requested information.',
          'You can also make a complaint regarding child sexual abuse at https://help.twitter.com/forms/cse',
          'Include the link to the profile and links to any relevant tweets displaying the content.',
          'Also include information such as your name, current age, age at the time the image was taken, that you are the person in the image/video, that you did not post it, did not agree to it being posted and want it removed, and if the image was sent to someone else.'
        ]
      }
    ]
  },
  'YouTube': {
    app: [
      {
        title: 'To make a complaint about a video:',
        intro: 'Follow these steps to report a video on YouTube:',
        steps: [
          'Log into YouTube',
          'Find the video you want to report in the app',
          'Tap on the three dots in the upper right corner of the video',
          'Tap "Report"',
          'Select the reason you are reporting the video (i.e., sexual content)',
          'Tap "Report"'
        ]
      }
    ],
    desktop: [
      {
        title: 'To make a complaint about a video:',
        intro: 'Follow these steps to report a video on YouTube:',
        steps: [
          'Log into YouTube',
          'Find the video you want to report',
          'Click on the “•••” located to the right below the video',
          'Click “Report”',
          'Select the most appropriate issue from the options provided (i.e., Sexual Content)',
          'Select the more appropriate reason (i.e., harassment or bullying)',
          'Click “Report”'
        ]
      },
      {
        title: 'To make a privacy complaint:',
        intro: 'A YouTube privacy complaint may also be submitted when:',
        steps: [
          'Your image is shown',
          'Your full legal name is shown',
          'Your voice is being broadcasted',
          'Your child is shown',
          'Access https://support.google.com/youtube/answer/142443?sjid=10745385026691974270-NA',
          'Tap "Continue"',
          'Select the appropriate responses',
          'Share what personal info was revealed',
          'Enter your contact information and select the most appropriate responses. Please note, the uploader of the content will receive the complaint from YouTube; your name and/or contact information will not be provided to the uploader without your consent.'
        ]
      },
      {
        title: 'YouTube Copyright Infringement (DMCA):',
        intro: 'If you believe your copyright has been infringed, follow these steps:',
        steps: [
          'Access https://www.youtube.com/copyright_complaint_form and provide the requested information.'
        ]
      }
    ]
  },
  'Other Sites Not Listed': {
    app: [
      {
        title: 'Determine where the image is hosted.',
        intro: 'Follow these steps to report an image or video hosted on a website not listed in our guides:',
        steps: [
          'Determine where the image is hosted.',
          'Obtain the image location of the image/video (To obtain an image location: right-click on the image and click “copy image address” or “copy image location” depending on your web browser).',
          'Locate the “Registrar Abuse Contact Email” in the Whois result.', ],},
          {  title: 'Contact the Provider by emailing the “Registrar Abuse Contact Email”.',
          steps: ['Include your age: it is important to tell them if you are a child/youth. Include your age at the time the image/video was taken as well as your current age.',
          'Say that you are the person in the image/video. If you are recognizable in the image/video, include this as well – this may give your report a higher priority.',
          'Say that you did not post the image/video, did not agree to it being posted, and want it removed. They need to know that you object to the continued posting of the image/video.'
        ]
      }
    ],
    desktop: [] // If there are desktop instructions, add them here
  },
};

export default function DirectPlatform() {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedPlatform, setSelectedPlatform] = useState(null);
  
  const filteredPlatforms = Object.keys(platforms).filter(platform =>
    platform.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
 <Helmet>
        <link rel="canonical" href="https://deepfakehelp.com/platforms" />
      </Helmet>


      <SEO {...seoData.platforms} 
        ogImage="/default-og-image.png"  // This should point to the public folder
        title={seoData.platforms.title}
        description={seoData.platforms.description}
      />
      <div className="max-w-4xl mx-auto p-8 font-sans">
        <h1 className="text-4xl font-semibold mb-8 text-center">
          Guides on How to Report Deepfakes Takedowns Directly to Platforms
        </h1>
        
        <div className="relative mb-8">
          <Search className="absolute left-4 top-3 text-gray-400" size={20} />
          <input
            type="text"
            placeholder="Search platforms..."
            className="w-full pl-12 pr-4 py-3 rounded-xl bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        {filteredPlatforms.length > 0 ? (
          <div className="space-y-4">
            {filteredPlatforms.map(platform => (
              <div key={platform} className="border rounded-xl overflow-hidden bg-white shadow-sm">
                <button
                  className="w-full p-4 text-left flex justify-between items-center hover:bg-gray-350"
                  onClick={() => setSelectedPlatform(selectedPlatform === platform ? null : platform)}
                >
                  <span className="text-xl font-medium">{platform}</span>
                  <ChevronUp 
                    className={`transform transition-transform ${selectedPlatform === platform ? '' : 'rotate-180'}`}
                    size={20}
                  />
                </button>
                
                {selectedPlatform === platform && (
                  <div className="p-4 border-t">
                    <div className="grid md:grid-cols-2 gap-8">
                      <div>
                        <h3 className="text-lg font-semibold mb-4">App Instructions</h3>
                        {platforms[platform].app.map((instruction, index) => (
                          <div key={index} className="mb-4">
                            <h4 className="font-semibold">{instruction.title}</h4>
                            <p className="text-gray-600 mb-2">{instruction.intro}</p>
                            <ol className="list-decimal list-inside space-y-2">
                              {instruction.steps.map((step, stepIndex) => (
                                <li key={stepIndex} className="text-gray-700">{step}</li>
                              ))}
                            </ol>
                          </div>
                        ))}
                      </div>
                      <div>
                        <h3 className="text-lg font-semibold mb-4">Desktop Instructions</h3>
                        {platforms[platform].desktop.map((instruction, index) => (
                          <div key={index} className="mb-4">
                            <h4 className="font-semibold">{instruction.title}</h4>
                            <p className="text-gray-600 mb-2">{instruction.intro}</p>
                            <ol className="list-decimal list-inside space-y-2">
                              {instruction.steps.map((step, stepIndex) => (
                                <li key={stepIndex} className="text-gray-700">{step}</li>
                              ))}
                            </ol>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center py-8">
            <p className="text-gray-600 mb-4">
              Sorry, we couldn't find details for this platform. Search for 'Other' to find general advice. 
            </p>
            <a 
              href="/contact" 
              className="text-blue-500 hover:text-blue-700 font-medium"
            >
              Send us a message with the name of the platform you need help with. We would try our best to add it to our site →
            </a>
          </div>
        )}
      </div>
    </>
  );
}
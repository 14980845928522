import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import emailjs from '@emailjs/browser'; // Import EmailJS
import Footer from './Footer'; // Import the Footer component
import SEO from './SEO';
import { seoData } from './seo.config';
import { Helmet } from 'react-helmet';



const ContactUs = () => {
  const [message, setMessage] = useState(''); // State for message
  const [success, setSuccess] = useState(false); // State for success message

  const handleSubmit = (e) => {
    e.preventDefault();

    // EmailJS send function
    emailjs.send('service_n3tkv28', 'template_hic47zi', { message }, 'E2EFYM3hRu6f6FEUR')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setSuccess(true); // Set success state
        setMessage(''); // Clear message field
      }, (err) => {
        console.error('FAILED...', err);
      });
  };

  return (
    <>

<Helmet>
        <link rel="canonical" href="https://deepfakehelp.com/about-us" />
      </Helmet>

      <SEO {...seoData.about}
  ogImage="/default-og-image.png"  // This should point to the public folder
  title={seoData.about.title}
         description={seoData.about.description}
      />
    <div className="min-h-screen bg-white">
      {/* Main Content */}
      <main className="container mx-auto px-4 py-8">
        {/* Information Section */}
        <section className="mt-8">
          <h2 className="text-4xl font-bold mb-8 text-blue-600">Why I Built DeepfakeHelp.com</h2>
          <p className="text-lg text-gray-700 mb-4">
            DeepFakeHelp.com is a community-driven site that relies on public and anonymous reports on deepfake cases at schools and universities.
          </p>
          <p className="text-gray-700 mb-4">
            A year ago, I started to collect and introduce AI tools to help entrepreneurs and creators boost their productivity. Over time, it became clear that many of these tools have dual uses. Like a knife, they can either be used to create a masterpiece Michelin Star dish or cause harm.
          </p>
          <p className="text-gray-700 mb-4">
          Now, seeing hundreds of cases targeting schoolgirls in just a month, I wondered: Am I the only one who views this as an extreme attack? It turned out that while others I spoke with agreed, an even bigger problem existed.
          </p>
          <p className="text-gray-700 mb-4">
          No one knew. There was no awareness that this was even possible.
          </p>
          <p className="text-gray-700 mb-4">
          So, I set out to gather all cases from the community, with the first goal of raising awareness. And not only stop there -- to provide guidance for victims..
          </p>
          <p className="text-gray-700 mb-4">
          To achieve this, I turned to AI. Sort of using AI to fight AI. So over a weekend, I learned how to put develop my first website with the help of an coding agent. Well… can I really say I "developed" if I was prompting an AI tool to build it for me? That’s a topic for another time.
          </p>
          <p className="text-gray-700 mb-4">
          And that’s how DeepfakeHelp.com was born.
          </p>
          <p className="text-gray-700 mb-4">
          We've got more community members joining to report their local school cases. And it's already getting attention globally.
          </p>
          <p className="text-gray-700 mb-4">
          We'll make the future bright!         
          </p>
          <p className="text-gray-700 mb-4">
          Rasmus Kors <br></br>
          Founder <br></br>
          DeepfakeHelp.com       
          </p>
          
          {/* Add more paragraphs as needed */}
        </section>
        
        {/* Button Links Section */}
        <section className="max-w-6xl mx-auto px-4 py-12 mt-12">
          <div className="flex flex-col items-center md:flex-row justify-center md:space-x-4 space-y-4 md:space-y-0">
            <Link to="/contact" className="w-full md:w-auto">
              <div className="bg-blue-300 rounded-lg p-4 shadow-lg hover:bg-blue-400 transition duration-200 cursor-pointer flex items-center justify-center max-w-xs mx-auto">
                <h3 className="text-xl font-bold text-white text-center">Anonymously Inform About An Affected School/University</h3>
              </div>
            </Link>
            <Link to="/report-deepfake-content" className="w-full md:w-auto">
              <div className="bg-blue-300 rounded-lg p-4 shadow-lg hover:bg-blue-400 transition duration-200 cursor-pointer flex items-center justify-center max-w-xs mx-auto">
                <h3 className="text-xl font-bold text-white text-center">Report & Takedown</h3>
              </div>
            </Link>
          </div>
        </section>
      </main>
    </div>
    </>
  );
};


export default ContactUs;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Footer from './Footer'; // Import the Footer component
import { Helmet } from 'react-helmet';

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen bg-white">
      {/* Main Content */}
      <main className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
        <p className="text-gray-700 mb-4">Last Updated: 2 October 2024</p>

        <section className="mt-8">
          <h2 className="text-2xl font-bold mb-4">1. Introduction</h2>
          <p className="text-gray-700 mb-4">
            This Privacy Policy explains how Baltasia Limited ("we," "us," or "our") collects, uses, and protects your information when you visit our website, Deepfake Help (the “Site”). 
          </p>

          <h2 className="text-2xl font-bold mb-4">2. Information We Collect</h2>
          <p className="text-gray-700 mb-4">
            We do not ask for or collect any personal information from users of the Site. 
          </p>

          <h2 className="text-2xl font-bold mb-4">3. Anonymous Reporting</h2>
          <p className="text-gray-700 mb-4">
            Users are encouraged to report anonymously the names of schools and universities with supporting material related to deepfake incidents. This information will be aggregated for awareness and resource purposes only.
          </p>

          <h2 className="text-2xl font-bold mb-4">4. Use of Google Analytics</h2>
          <p className="text-gray-700 mb-4">
            We use Google Analytics to monitor user engagement and demographic trends on the Site. Google Analytics collects information such as how often users visit the Site, what pages they visit, and what other sites were used prior to coming to our Site. This information is used to improve our Site and Services.
          </p>

          <h2 className="text-2xl font-bold mb-4">5. Limitations on Content Submission</h2>
          <p className="text-gray-700 mb-4">
            Under no circumstances should users submit deepfake materials to us. We direct users to appropriate organizations that offer support for such cases.
          </p>

          <h2 className="text-2xl font-bold mb-4">6. No Legal Advice</h2>
          <p className="text-gray-700 mb-4">
            The information provided on the Site is for educational purposes only. We do not provide legal advice. Users should seek advice from qualified legal professionals regarding their individual circumstances.
          </p>

          <h2 className="text-2xl font-bold mb-4">7. Third-Party Links</h2>
          <p className="text-gray-700 mb-4">
            Our Site may contain links to third-party websites. We do not control these websites and are not responsible for their content or privacy practices. We encourage users to review the privacy policies of any third-party websites they visit.
          </p>

          <h2 className="text-2xl font-bold mb-4">8. Changes to This Privacy Policy</h2>
          <p className="text-gray-700 mb-4">
            We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date. Your continued use of the Site after changes are posted constitutes your acceptance of the new Privacy Policy.
          </p>

          <h2 className="text-2xl font-bold mb-4">9. Contact Information</h2>
          <p className="text-gray-700 mb-4">
            If you have any questions or concerns about this Privacy Policy, please contact us at:
          </p>
          <p className="text-gray-700 mb-4">
            <strong>Deepfake Help</strong><br />
            <strong>Baltasia Limited</strong><br />
            Email: contact (at) deepfakehelp.com<br />
          </p>
        </section>
      </main>

       
    </div>
  );
};

export default PrivacyPolicy;

import React from 'react';
import { Link } from 'react-router-dom';
import Footer from './Footer'; // Import the Footer component
import SEO from './SEO';
import { seoData } from './seo.config';
import { Helmet } from 'react-helmet';


const ReportOverview = () => {
  const platforms = [
    { title: "Report directly to platforms", description: "Learn step-by-step how you can report deepfake takedowns to platforms directly.", link: "/platforms" },
    { title: "Report via organisations", description: "Contact organisations that support you in reporting deepfake takedowns.", link: "/organizations" },
    { title: "Find an attorney or law firm", description: "Find attorneys and lawyer firms to get legal advice and help.", link: "/deepfake-lawyers" },
    // Add more platform objects as needed
  ];

  return (
    <>

<Helmet>
        <link rel="canonical" href="https://deepfakehelp.com/report-deepfake-content" />
      </Helmet>

      <SEO {...seoData.reportOverview} 
  ogImage="/default-og-image.png"  // This should point to the public folder
  title={seoData.reportOverview.title}
      description={seoData.reportOverview.description}
      />
      <div className="min-h-screen bg-white flex flex-col">
        <main className="container mx-auto px-4 py-6 flex-grow">
          <section className="mt-8">
          <h1 className="text-4xl font-bold mb-8 text-blue-600">Deepfake Reporting and Takedown Requesting</h1>
            <h2 className="text-xl mb-8 text-blue-600">Select Your Preferred Method</h2>

            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
              {platforms.map((platform, index) => (
                <div key={index} className="border rounded-lg p-4 shadow-md hover:shadow-lg transition-shadow">
                  <h3 className="text-xl font-semibold">{platform.title}</h3>
                  <p className="text-gray-600 mt-2">{platform.description}</p>
                  <Link to={platform.link} className="text-blue-600 hover:underline mt-4 block">
                    Get Started
                  </Link>
                </div>
              ))}
            </div>
          </section>
        </main>
      </div>
    </>
  );
};

export default ReportOverview;
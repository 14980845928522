// src/components/Footer.js
import React from 'react';
import { FaLinkedin } from 'react-icons/fa'; // Importing LinkedIn icon

const Footer = () => {
  return (
    <footer className="bg-blue-200 text-grey p-8 text-center mt-8 rounded-lg shadow-lg">
      <p className="text-xl font-bold mb-4">Dedicated to Protecting Your Digital Identity</p>
      <div className="flex justify-center items-center mb-4">
        <span className="bg-blue-600 p-3 rounded-full mr-3">🛡️</span>
        <span className="text-sm">Copyright © 2024, Deepfake Help. All rights reserved.</span>
      </div>
      <div className="flex flex-col md:flex-row justify-center md:space-x-8 space-y-4 md:space-y-0 mb-4">
        <a href="/terms-and-conditions" className="hover:text-blue-300">Terms and Conditions</a>
        <a href="/privacy-policy" className="hover:text-blue-300">Privacy Policy</a>
        <a href="/about-us" className="hover:text-blue-300">About Us</a>
        <a href="/contact" className="hover:text-blue-300">Contact</a>
        <a href="https://www.linkedin.com/company/deepfakehelp" className="flex items-center justify-center hover:text-blue-300" target="_blank" rel="noopener noreferrer">
          <FaLinkedin className="mr-1" /> LinkedIn
        </a>
      </div>
    </footer>
  );
};

export default Footer;

import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate(); // Initialize useNavigate

  // Function to handle navigation to the contact page and scroll
  const handleReportSchoolClick = () => {
    navigate('/contact'); // Navigate to the Contact Us page
  };

  return (
    <header className="bg-white shadow-md relative z-50">
      <div className="container mx-auto px-4 py-3">
        <div className="flex justify-between items-center">
          <Link to="/" className="text-2xl font-bold text-blue-600">Deepfake Help</Link>
          
          {/* Mobile menu button */}
          <button 
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="md:hidden p-2 rounded-md hover:bg-gray-100 focus:outline-none"
          >
            <svg 
              className="h-6 w-6" 
              fill="none" 
              viewBox="0 0 24 24" 
              stroke="currentColor"
            >
              {isMenuOpen ? (
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth={2} 
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth={2} 
                  d="M4 6h16M4 12h16M4 18h16"
                />
              )}
            </svg>
          </button>

          {/* Desktop navigation */}
          <nav className="hidden md:flex items-center space-x-6">
            <Link to="/getting-started" className="text-gray-600 hover:text-blue-600 transition-colors">
              Learn More
            </Link>
            <Link to="/report-deepfake-content" className="text-gray-600 hover:text-blue-600 transition-colors">Report & Takedown</Link>
            <Link to="/about-us" className="text-gray-600 hover:text-blue-600 transition-colors">About Us</Link>
            <button 
              onClick={handleReportSchoolClick} // Call the click handler here
              className="bg-green-500 text-white px-6 py-2 rounded-full hover:bg-green-600 transition-colors"
            >
              Inform About a School
            </button>
          </nav>
        </div>

        {/* Mobile navigation */}
        {isMenuOpen && (
          <nav className="md:hidden space-y-2 absolute w-full left-0 right-0 bg-white shadow-lg px-4 py-2">
            <div className="container mx-auto">
              <Link 
                to="/getting-started" 
                className="block text-gray-600 hover:text-blue-600 transition-colors text-center py-2"
                onClick={() => setIsMenuOpen(false)}
              >
                Learn More
              </Link>
              <Link 
                to="/report-deepfake-content" 
                className="block text-gray-600 hover:text-blue-600 transition-colors text-center py-2"
                onClick={() => setIsMenuOpen(false)}
              >
                Report & Takedown
              </Link>
              <Link 
                to="/about-us" 
                className="block text-gray-600 hover:text-blue-600 transition-colors text-center py-2"
                onClick={() => setIsMenuOpen(false)}
              >
                About Us
              </Link>
              <button 
                onClick={() => {
                  handleReportSchoolClick();
                  setIsMenuOpen(false);
                }}
                className="w-full block bg-green-500 text-white px-6 py-2 rounded-full hover:bg-green-600 transition-colors text-center"
              >
                Inform About a School
              </button>
            </div>
          </nav>
        )}
      </div>
    </header>
  );
};

export default Header;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import emailjs from '@emailjs/browser';
import SEO from './SEO';
import { seoData } from './seo.config';
import { Helmet } from 'react-helmet';


const ContactUs = () => {
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Load reCAPTCHA script
    const loadRecaptcha = () => {
      const script = document.createElement('script');
      script.src = "https://www.google.com/recaptcha/api.js";
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    };

    loadRecaptcha();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    
    try {
      // Get reCAPTCHA response
      const recaptchaResponse = window.grecaptcha?.getResponse();
      
      if (!recaptchaResponse) {
        setError('Please complete the reCAPTCHA verification');
        setLoading(false);
        return;
      }

      // Prepare email template parameters
      const templateParams = {
        message: message,
        'g-recaptcha-response': recaptchaResponse,
      };

      // Send email using emailjs
      const response = await emailjs.send(
        'service_n3tkv28',  // Your EmailJS service ID
        'template_hic47zi', // Your EmailJS template ID
        templateParams,
        'E2EFYM3hRu6f6FEUR' // Your EmailJS public key
      );

      if (response.status === 200) {
        setSuccess(true);
        setMessage('');
        window.grecaptcha.reset();
      }
    } catch (err) {
      console.error('Error sending message:', err);
      setError('Failed to send message. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>

<Helmet>
        <link rel="canonical" href="https://deepfakehelp.com/contact" />
      </Helmet>

      <SEO {...seoData.contact} 
  ogImage="/default-og-image.png"  // This should point to the public folder
  title={seoData.contact.title}
        description={seoData.contact.description}
      />
      <div className="min-h-screen bg-white">
        <main className="container mx-auto px-4 py-8">
          <section id="report-form" className="relative z-10">
            <div className="max-w-2xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden mt-12">
              <div className="p-6">
                <h2 className="text-xl font-semibold mb-4">
                  Want to report anonymously a new school/university case? Or got more thoughts and ideas?
                </h2>
                <form className="space-y-4" onSubmit={handleSubmit}>
                  <div>
                    <label htmlFor="message" className="block text-sm font-medium text-gray-700">
                      Send us a Message!
                    </label>
                    <textarea
                      id="message"
                      name="message"
                      rows="4"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                      required
                      placeholder="If you're reporting a school name, do provide your supporting news article or notice. All other details should be should be reported directly to your school/university leadership and police."
                    ></textarea>
                  </div>
                  
                  <div className="g-recaptcha" data-sitekey="6LcVaFQqAAAAAKVflKudLbTplwXQ02o7_eyJYR2A"></div>
                  
                  {error && (
                    <p className="text-red-500 text-sm">{error}</p>
                  )}
                  
                  <div>
                    <button
                      type="submit"
                      disabled={loading}
                      className={`w-full ${loading ? 'bg-blue-300' : 'bg-blue-400'} text-white px-4 py-2 rounded hover:bg-blue-300 transition-colors duration-300`}
                    >
                      {loading ? 'Sending...' : 'Send Message'}
                    </button>
                  </div>
                  
                  {success && (
                    <div className="bg-green-50 border border-green-200 rounded p-4">
                      <p className="text-green-600">
                        Thank you for your message; we've successfully received it!
                      </p>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </section>

          <section className="max-w-6xl mx-auto px-4 py-12 mt-12">
            <div className="flex flex-col sm:flex-row justify-center gap-6">
              <Link to="/about-us" className="w-full">
                <div className="bg-blue-300 rounded-lg p-4 shadow-lg hover:bg-blue-400 transition duration-200 cursor-pointer flex items-center justify-center min-h-[100px]">
                  <h3 className="text-lg sm:text-xl font-bold text-white text-center">
                    About Us
                  </h3>
                </div>
              </Link>
              <Link to="/report-deepfake-content" className="w-full">
                <div className="bg-blue-300 rounded-lg p-4 shadow-lg hover:bg-blue-400 transition duration-200 cursor-pointer flex items-center justify-center min-h-[100px]">
                  <h3 className="text-lg sm:text-xl font-bold text-white text-center">
                    Report & Takedown
                  </h3>
                </div>
              </Link>
            </div>
          </section>
        </main>
      </div>
    </>
  );
};

export default ContactUs;
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Footer from './Footer'; // Import the Footer component
import { Helmet } from 'react-helmet';

const TermsAndConditions = () => {
  return (
    <div className="min-h-screen bg-white">
      {/* Main Content */}
      <main className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-4">Terms and Conditions</h1>
        <p className="text-gray-700 mb-4">Last Updated: 2 October 2024</p>

        <section className="mt-8">
          <h2 className="text-2xl font-bold mb-4">1. Acceptance of Terms</h2>
          <p className="text-gray-700 mb-4">
            By accessing or using the Deepfake Help website (the “Site”), you agree to comply with and be bound by these Terms and Conditions (“Terms”). If you do not agree with these Terms, please do not use the Site.
          </p>

          <h2 className="text-2xl font-bold mb-4">2. Changes to Terms</h2>
          <p className="text-gray-700 mb-4">
            Baltasia Limited reserves the right to modify these Terms at any time. Any changes will be effective immediately upon posting on the Site. Your continued use of the Site after changes are posted constitutes your acceptance of the new Terms.
          </p>

          <h2 className="text-2xl font-bold mb-4">3. Services Provided</h2>
          <p className="text-gray-700 mb-4">
            Deepfake Help provides resources, supporting guides, and expert contacts related to deepfake incidents (“Services”). The Services are intended for educational purposes only and should not be construed as legal advice. Specifically:
          </p>
          <ul className="list-disc list-inside mb-4">
            <li>We do not ask for or collect personal information from users.</li>
            <li>Users should not send us deepfake materials; instead, we direct them to organizations that offer support.</li>
            <li>We aggregate anonymous reports of school and university names, along with supporting materials, into one place.</li>
            <li>Guides provided on our Site include information from platforms on how to report images and videos that have been created without consent.</li>
          </ul>

          <h2 className="text-2xl font-bold mb-4">4. User Obligations</h2>
          <p className="text-gray-700 mb-4">
            You agree to use the Site and Services for lawful purposes only. You must not:
          </p>
          <ul className="list-disc list-inside mb-4">
            <li>Use the Site in any way that violates applicable federal, state, or local law.</li>
            <li>Transmit any advertising or promotional material without our prior written consent.</li>
            <li>Engage in any conduct that restricts or inhibits anyone’s use or enjoyment of the Site.</li>
            <li>Submit any deepfake materials to us, as we do not accept or handle such content.</li>
          </ul>

          <h2 className="text-2xl font-bold mb-4">5. Intellectual Property Rights</h2>
          <p className="text-gray-700 mb-4">
            All content, trademarks, and other intellectual property on the Site are the property of Baltasia Limited or its licensors. You are granted a limited, non-exclusive, non-transferable license to access and use the Site for personal, non-commercial purposes.
          </p>

          <h2 className="text-2xl font-bold mb-4">6. Limitation of Liability</h2>
          <p className="text-gray-700 mb-4">
            Baltasia Limited is not liable for any direct, indirect, incidental, consequential, or punitive damages arising from your access to or use of the Site or Services. Your use of the Site and Services is at your own risk.
          </p>

          <h2 className="text-2xl font-bold mb-4">7. Indemnification</h2>
          <p className="text-gray-700 mb-4">
            You agree to indemnify and hold harmless Baltasia Limited, its affiliates, and their respective officers, directors, employees, and agents from any claims, liabilities, damages, losses, or expenses arising from your use of the Site or Services, or your violation of these Terms.
          </p>

          <h2 className="text-2xl font-bold mb-4">8. Contact Information</h2>
          <p className="text-gray-700 mb-4">
            For any questions or concerns regarding these Terms, please contact us at:
          </p>
          <p className="text-gray-700 mb-4">
            <strong>Deepfake Help</strong><br />
            <strong>Baltasia Limited</strong><br />
            Email: contact (at) deepfakehelp.com<br />
          </p>
        </section>
      </main>

       
    </div>
  );
};

export default TermsAndConditions;

import React, { useState, useMemo } from 'react';
import { ComposableMap, Geographies, Geography, Marker } from 'react-simple-maps';
import { motion } from 'framer-motion';
import { Tooltip } from 'react-tooltip';
import './AnimatedMap.css';

// Import the GeoJSON data
import geoJsonData from './world-countries.json';

const AnimatedMapComponent = ({ casesData }) => {
  const [selectedCountry, setSelectedCountry] = useState(null);

  // Updated country positions with correct coordinates
  const countryPositions = {
    'Korea': [127.7669, 35.9078],
    'Spain': [-3.7492, 40.4637],
    'United States of America': [-95.7129, 37.0902],
    'Australia': [133.7751, -25.2744],
    'Mexico': [-102.5528, 23.6345],
    'Brazil': [-51.9253, -14.2350],
    'Canada': [-106.3468, 56.1304],
    'China': [104.1954, 35.8617],
    'Greece': [21.8243, 39.0742],
    'India': [78.9629, 20.5937],
    'Italy': [12.5674, 41.8719],
    'United Kingdom': [-3.4360, 55.3781]
  };

  // Create a list of all countries with their case data
  const allCountryData = useMemo(() => {
    const countryCounts = casesData.reduce((acc, item) => {
      acc[item.country] = (acc[item.country] || 0) + 1;
      return acc;
    }, {});

    return Object.entries(countryCounts).map(([country, value]) => ({
      country,
      value,
      position: countryPositions[country] || [0, 0]
    }));
  }, [casesData]);

  // Limit the sidebar to show only top 5 countries
  const topFiveCountries = useMemo(() => {
    return [...allCountryData].sort((a, b) => b.value - a.value).slice(0, 5);
  }, [allCountryData]);

  const handleCountryHover = (country) => {
    setSelectedCountry(country);
  };

  const PulsingMarker = ({ coordinates, value }) => (
    <g transform={`translate(${coordinates})`}>
      <motion.circle
        r={6} // Increased from 4
        fill="#93c5fd"
        animate={{
          scale: [3, 5, 3], // Increased scale for more visibility
          opacity: [0.7, 0.3, 0.7],
        }}
        transition={{
          duration: 2,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      />
      <circle r={3} fill="#FF0000" /> {/* Increased from 2 */}
      <text
        textAnchor="middle"
        y={-12} // Adjusted to not overlap with larger marker
        style={{
          fontFamily: "Arial",
          fontSize: "12px", // Increased from 8px
          fontWeight: "bold",
          fill: "#FF0000",
        }}
      >
        {value}
      </text>
    </g>
  );

  return (
    <div className="map-container">
     <ComposableMap
  projection="geoMercator"
  projectionConfig={{
    scale: 160, // Adjusted scale for zooming in a bit more
    center: [0, -10], // Shifted center downward (latitude adjusted from 30 to 20 to push map upward)
    translate: [400, 300] // Adjust the X and Y position further to move it up
  }}
>

        <Geographies geography={geoJsonData}>
          {({ geographies }) =>
            geographies.map((geo) => {
              const isActive = selectedCountry?.country === geo.properties.name;
              return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  fill={isActive ? "#FF5733" : "#F5F4F6"}
                  stroke="#D6D6DA"
                  style={{
                    default: {
                      outline: "none",
                      transition: "all 250ms"
                    },
                    hover: {
                      fill: "#FF8866",
                      outline: "none"
                    }
                  }}
                />
              );
            })
          }
        </Geographies>
        {allCountryData.map((country, index) => (
          <Marker key={index} coordinates={country.position}>
            <PulsingMarker coordinates={[0, 0]} value={country.value} />
          </Marker>
        ))}
      </ComposableMap>
      <div className="country-list">
        <h2>Top Reported Countries</h2>
        <div className="button-container">
          {topFiveCountries.map((country, index) => (
            <motion.button
              key={index}
              className={`country-button ${selectedCountry?.country === country.country ? 'active' : ''}`}
              onMouseEnter={() => handleCountryHover(country)}
              onMouseLeave={() => setSelectedCountry(null)}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              {country.country}: {country.value}
            </motion.button>
          ))}
        </div>
      </div>
      <Tooltip id="map-tooltip" />
    </div>
  );
};

export default AnimatedMapComponent;

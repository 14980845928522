import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import emailjs from '@emailjs/browser'; // Import EmailJS
import Footer from './Footer'; // Import the Footer component
import SEO from './SEO';
import { seoData } from './seo.config';
import { Helmet } from 'react-helmet';


const GettingStarted = () => {
  

  return (
    <>

<Helmet>
        <link rel="canonical" href="https://deepfakehelp.com/getting-started" />
      </Helmet>

      <SEO {...seoData.gettingStarted}
  ogImage="/default-og-image.png"  // This should point to the public folder
  title={seoData.lawyers.title}
        description={seoData.lawyers.description}
      />
    <div className="min-h-screen bg-white">
      {/* Main Content */}
      <main className="container mx-auto px-4 py-8">
        {/* New Help Section */}
        <section className="mt-8">
          <h2 className="text-4xl font-bold mb-8 text-blue-600">Getting Started</h2>
          <p className="text-lg text-gray-700 mb-4">Please select the option that best describes your situation:</p>
          <div className="flex space-x-4">
            <button 
              className="w-full bg-blue-300 text-white px-4 py-2 rounded hover:bg-blue-400 transition-colors duration-300"
              onClick={() => {
                const section = document.getElementById('affected-section');
                const offset = 100; // Adjust this value based on your navigation height
                const top = section.getBoundingClientRect().top + window.pageYOffset - offset;
                window.scrollTo({ top, behavior: 'smooth' });
              }}
            >
              I Am Affected
            </button>
            <button 
              className="w-full bg-blue-300 text-white px-4 py-2 rounded hover:bg-blue-400 transition-colors duration-300"
              onClick={() => {
                const section = document.getElementById('someone-affected-section');
                const offset = 100; // Adjust this value based on your navigation height
                const top = section.getBoundingClientRect().top + window.pageYOffset - offset;
                window.scrollTo({ top, behavior: 'smooth' });
              }}
            >
              Someone I Know Is Affected
            </button>
            <button 
              className="w-full bg-blue-300 text-white px-4 py-2 rounded hover:bg-blue-400 transition-colors duration-300"
              onClick={() => {
                const section = document.getElementById('saw-deepfake-section');
                const offset = 100; // Adjust this value based on your navigation height
                const top = section.getBoundingClientRect().top + window.pageYOffset - offset;
                window.scrollTo({ top, behavior: 'smooth' });
              }}
            >
              I Saw a Deepfake
            </button>
          </div>
        </section>

        {/* If You Selected "I Am Affected" */}
        <section id="affected-section" className="mt-8">
          <h3 className="text-xl font-semibold mb-4">I Am Affected</h3>
          <p className="text-lg text-gray-700 mb-4">Acknowledge Your Feelings: Experiencing deepfake content can be overwhelming. It’s important to know that you are not alone—many people and organizations are here to support you.</p>
          <p className="text-lg text-gray-700 mb-4">Assess Your Situation: To address the situation effectively, consider the following:</p>
          <ul className="list-disc pl-5 mb-4">
            <li>Is the content explicit or harmful?</li>
            <li>Are you the person depicted in the content?</li>
            <li>Where is the content shared?</li>
          </ul>
          <p className="text-lg text-gray-700 mb-4">Document the Content: Take these crucial steps to document the deepfake:</p>
          <ul className="list-disc pl-5 mb-4">
            <li>Take Screenshots: Capture clear images of the content and associated comments.</li>
            <li>Record URLs and Dates: Note where the deepfake appears and when you found it.</li>
            <li>Save Files: If possible, keep copies of the deepfake files securely.</li>
          </ul>
          <p className="text-lg text-gray-700 mb-4">Removing Content via Reporting: Report on Social Media Platforms: Follow the reporting procedures on platforms like Facebook, Instagram, Twitter, and YouTube.</p>
          <p className="text-lg text-gray-700 mb-4">Utilize Reporting Organizations: If you’re under 18, report to Take It Down (takeitdown.ncmec.org). If you’re over 18, use StopNCII.org (stopncii.org).</p>
          <p className="text-lg text-gray-700 mb-4">Additional Support: Recognize the emotional toll this experience can take. Reach out for support from mental health professionals, friends, or organizations dedicated to helping victims.</p>
        </section>

        {/* If You Selected "Someone I Know Is Affected" */}
        <section id="someone-affected-section" className="mt-8">
          <h3 className="text-xl font-semibold mb-4">Someone I Know Is Affected</h3>
          <p className="text-lg text-gray-700 mb-4">Show Your Support: If someone you care about is facing the impact of deepfake content, here’s how you can help them:</p>
          <ul className="list-disc pl-5 mb-4">
            <li>Listen and Validate: Offer emotional support and listen to their concerns.</li>
            <li>Help Them Assess the Situation: Assist them in answering questions about the content and its impact.</li>
            <li>Document the Content: Encourage them to take screenshots and record URLs as evidence.</li>
            <li>Guide Them in Reporting: Help them understand how to report the content and access support resources.</li>
          </ul>
          <p className="text-lg text-gray-700 mb-4">Additional Resources: Provide them with contact information for organizations that can offer professional help.</p>
        </section>

        {/* If You Selected "I Saw a Deepfake" */}
        <section id="saw-deepfake-section" className="mt-8">
          <h3 className="text-xl font-semibold mb-4">I Saw a Deepfake</h3>
          <p className="text-lg text-gray-700 mb-4">Reporting Steps: If you’ve encountered deepfake content that may harm someone, here’s what you can do:</p>
          <ul className="list-disc pl-5 mb-4">
            <li>Document the Content: Capture screenshots, record URLs, and note dates.</li>
            <li>Report on Social Media Platforms: Follow the reporting procedures on platforms where the deepfake appears.</li>
            <li>Notify Organizations: If appropriate, inform the affected person about resources like Take It Down or StopNCII.org.</li>
          </ul>
          <p className="text-lg text-gray-700 mb-4">Additional Support: Encourage the victim to seek emotional support from trusted individuals or professionals.</p>
        </section>

        {/* Final Note */}
        <section className="mt-8">
          <h3 className="text-xl font-semibold mb-4">You Are Not Alone</h3>
          <p className="text-lg text-gray-700 mb-4">Whatever your situation, please remember that support is available, and you are not alone. If you have any questions or need further assistance, don’t hesitate to contact our support team. We are here to help you through this.</p>
        </section>

        

        {/* Existing Button Links Section */}
        <section className="max-w-6xl mx-auto px-4 py-4 mt-16">
          <div className="flex flex-col md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-4">
            <Link to="/contact">
              <div className="bg-blue-300 rounded-lg p-4 shadow-lg hover:bg-blue-400 transition duration-200 cursor-pointer flex items-center justify-center w-full md:w-64">
                <h3 className="text-xl font-bold text-white text-center">Anonymously Inform About An Affected School/University</h3>
              </div>
            </Link>
            <Link to="/report-deepfake-content">
              <div className="bg-blue-300 rounded-lg p-4 shadow-lg hover:bg-blue-400 transition duration-200 cursor-pointer flex items-center justify-center w-full md:w-64">
                <h3 className="text-xl font-bold text-white text-center">Report & Takedown</h3>
              </div>
            </Link>
          </div>
        </section>
      </main>
    </div>
    </>
  );
 
};

export default GettingStarted;


import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import SEO from './SEO';
import { seoData } from './seo.config';
import { Helmet } from 'react-helmet';


const FindOrganizations = () => {
  const organizations = [
    {
      country: "Worldwide",
      name: "The National Center for Missing & Exploited Children",
      description: "A free service to help remove nude, partially nude, or explicit images or videos shared of individuals under 18.",
      contact: "https://takeitdown.ncmec.org/"
    },
    {
      country: "Worldwide",
      name: "StopNCII.org, operated by the Revenge Porn Helpline",
      description: "A platform dedicated to removing nonconsensual intimate images from the internet of individuals over 18.",
      contact: "https://stopncii.org/"
    },
    {
      country: "South Korea",
      name: "Cyber Crime Investigation Bureau (CCIB)",
      description: "Government agency dealing with cybercrime, including online harassment and deepfakes.",
      contact: "police.go.kr\nPhone: 182"
    },
    {
      country: "South Korea",
      name: "Korea Internet & Security Agency (KISA)",
      description: "Provides information on internet security, including reporting online harassment.",
      contact: "kisa.or.kr\nEmail: privacy@kisa.or.kr"
    },
    {
      country: "South Korea",
      name: "Korean Women's Development Institute (KWDI)",
      description: "Offers support and resources for women facing online harassment, including educational materials.",
      contact: "kwdi.re.kr\nEmail: kwdi@kwdi.re.kr"
    },
    {
      country: "Spain",
      name: "Asociación Internacional Teléfono de la Esperanza (ASITES)",
      description: "Provides support and resources for individuals facing mental health issues, including harassment.",
      contact: "telefonoesperanza.org\nPhone: 717 003 717"
    },
    {
      country: "Spain",
      name: "Fundación ANAR",
      description: "Focuses on protecting children and adolescents, offering a helpline for youth experiencing abuse.",
      contact: "anar.org\nPhone: 900 202 010"
    },
    {
      country: "Spain",
      name: "Senda (Cyber Bullying)",
      description: "Offers resources about cyberbullying and harassment for students.",
      contact: "senda.es"
    },
    {
      country: "Australia",
      name: "eSafety Commissioner",
      description: "Provides online safety education and resources for victims of cyberbullying and deepfakes.",
      contact: "esafety.gov.au\nPhone: 1300 363 992"
    },
    {
      country: "Australia",
      name: "ReachOut Australia",
      description: "Offers mental health support and resources for young people dealing with online harassment.",
      contact: "reachout.com"
    },
    {
      country: "United States",
      name: "Cyber Civil Rights Initiative (CCRI)",
      description: "Nonprofit dedicated to fighting online harassment and supporting victims of deepfakes.",
      contact: "cybercivilrights.org\nEmail: info@cybercivilrights.org"
    },
    {
      country: "United States",
      name: "National Center for Missing & Exploited Children (NCMEC)",
      description: "Provides resources about online safety and how to report exploitation and harassment.",
      contact: "missingkids.org\nPhone: 1-800-THE-LOST (1-800-843-5678)"
    },
    {
      country: "Mexico",
      name: "Red por la Infancia (Children's Rights Network)",
      description: "Focused on protecting children's rights in the digital environment, offering resources and advocacy.",
      contact: "redporlainfancia.org"
    },
    {
      country: "Mexico",
      name: "Secretaría de Seguridad y Protección Ciudadana",
      description: "Offers a national helpline for victims of cybercrime and online harassment.",
      contact: "ssp.gob.mx\nPhone: 800 911 2000"
    }
  ];

  return (
    <>

<Helmet>
        <link rel="canonical" href="https://deepfakehelp.com/organizations" />
      </Helmet>
    
      <SEO {...seoData.organizations}
  ogImage="/default-og-image.png"  // This should point to the public folder
  title={seoData.organizations.title}
        description={seoData.organizations.description}
      />
    <div className="min-h-screen bg-white">
      <main className="container mx-auto px-4 py-8">
        <section className="mt-8">
          <h1 className="text-4xl font-bold mb-8 text-blue-600">Find Organizations Supporting Deepfake Victims</h1>
          
          <div className="overflow-x-auto">
            {/* On mobile, display as cards instead of table */}
            <div className="block md:hidden">
              {organizations.map((org, index) => (
                <div key={index} className="mb-6 p-4 border rounded-lg shadow">
                  <div className="font-bold text-gray-600 mb-1">{org.country}</div>
                  <div className="font-bold text-lg mb-2">{org.name}</div>
                  <div className="text-gray-600 mb-3">{org.description}</div>
                  <div className="whitespace-pre-line">
                    {org.contact.startsWith('http') ? (
                      <a
                        href={org.contact}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded inline-block"
                      >
                        Visit Site
                      </a>
                    ) : (
                      org.contact
                    )}
                  </div>
                </div>
              ))}
            </div>

            {/* Original table, hidden on mobile */}
            <table className="w-full border-collapse hidden md:table">
              <thead>
                <tr className="bg-blue-100">
                  <th className="p-3 text-left">Country</th>
                  <th className="p-3 text-left">Organization Name</th>
                  <th className="p-3 text-left">Description</th>
                  <th className="p-3 text-left">Contact Information</th>
                </tr>
              </thead>
              <tbody>
                {organizations.map((org, index) => (
                  <tr key={index} className="border-b">
                    <td className="p-3">{org.country}</td>
                    <td className="p-3">{org.name}</td>
                    <td className="p-3">{org.description}</td>
                    <td className="p-3 whitespace-pre-line">
                      {org.contact.startsWith('http') ? (
                        <a
                          href={org.contact}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
                        >
                          Visit Site
                        </a>
                      ) : (
                        org.contact
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </section>
      </main>
    </div>
    </>
  );
};

export default FindOrganizations;

import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import SEO from './SEO';
import { seoData } from './seo.config';
import { Helmet } from 'react-helmet';


const FindLawyer = () => {
  const lawyers = [
    {
      country: "South Korea",
      name: "Keun Woo Lee",
      lawfirm: "Yoon & Yang LLC",
      contact: "https://www.yoonyang.com/eng/professionals/profile/201"
    }
  ];

  return (
    <>
  <Helmet>
        <link rel="canonical" href="https://deepfakehelp.com/deepfake-lawyers" />
      </Helmet>

      <SEO {...seoData.lawyers} 
      
      ogImage="/default-og-image.png"  // This should point to the public folder
      title={seoData.lawyers.title}
      description={seoData.lawyers.description}
      />
    <div className="min-h-screen bg-white">
      <main className="container mx-auto px-4 py-8">
        <section className="mt-8">
          
          <h1 className="text-4xl font-bold mb-8 text-blue-600">Deepfake Attorneys and Law Firms</h1>
          <h2 className="text-xl mb-8 text-blue-600">Find a deepfake lawyer</h2>


          <div className="block md:hidden">
            {lawyers.map((lawyer, index) => (
              <div key={index} className="mb-4 p-4 border rounded-lg shadow">
                <div className="mb-2">
                  <span className="font-semibold">Country:</span> {lawyer.country}
                </div>
                <div className="mb-2">
                  <span className="font-semibold">Name:</span> {lawyer.name}
                </div>
                <div className="mb-2">
                  <span className="font-semibold">Law Firm:</span> {lawyer.lawfirm}
                </div>
                <div className="mt-3">
                  <a 
                    href={lawyer.contact}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="block w-full text-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded text-sm"
                  >
                    Contact Now
                  </a>
                </div>
              </div>
            ))}
          </div>

          <div className="hidden md:block overflow-x-auto">
            <table className="w-full border-collapse">
              <thead>
                <tr className="bg-blue-1000 text-red">
                  <th className="p-3 text-left">Country</th>
                  <th className="p-3 text-left">Name</th>
                  <th className="p-3 text-left">Law Firm</th>
                  <th className="p-3 text-left"></th>
                </tr>
              </thead>
              <tbody>
                {lawyers.map((lawyer, index) => (
                  <tr key={index} className="border-b">
                    <td className="p-3">{lawyer.country}</td>
                    <td className="p-3">{lawyer.name}</td>
                    <td className="p-3">{lawyer.lawfirm}</td>
                    <td className="p-3 text-center">
                      <a 
                        href={lawyer.contact}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded text-sm whitespace-nowrap"
                      >
                        Contact Now
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </section>
      </main>
    </div>
    </>
  );
};

export default FindLawyer;
